<template>
  <div class="form-elements">
    <div class="va-row">
      <div class="flex md12">
        <vuestic-widget>
          <form
            action=""
            autocomplete="off"
          >
            <div class="va-row">
              <div class="flex md8 xs12 ">
                <fieldset>
                  <div class="form-group">
                    <div class="input-group ">
                      <input
                        required
                        v-model="modelo.nome"
                      />
                      <label
                        class="control-label"
                        for="tipo"
                      >Relato
                      </label>
                    </div>
                    <button class="ml-3 btn btn-primary btn-micro"
                    @click.stop.prevent="criarModelo">Confirmar</button>
                  </div>
                </fieldset>
              </div>
            </div>
          </form>
        </vuestic-widget>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'cadastrar-relatos',
  data() {
    return {
      modelo: {
        nome: '',
      },
    };
  },
  methods: {
    message(type, title) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type,
        title,
        showConfirmButton: false,
        timer: 2000,
      });
    },
    criarModelo() {
      this.$axios.post('/api/modelo/', this.modelo).then((res) => {
        this.$router.push({ name: 'editar-relato', params: { id: res.data.id } });
      }).catch(() => {
        this.message('error', 'Erro ao cadastrar um modelo');
      });
    },
  },
};
</script>
